<template>
  <loading v-if="isLoading" />
  <div v-else class="course-students">
    <b-row>
      <b-card>
        <b-card-sub-title>
          {{ courseDetails.name }}
          <span class="gray-text">.{{ $t("g.Students") }}</span>
        </b-card-sub-title>
        <b-card-sub-title class="gray-text" v-if="courseDetails.edu_years">
          {{ $t("g.Grade") }}
          <span
            class="spcial-text"
            v-for="(eduYear, index) in courseDetails.edu_years"
            :key="index"
          >
            .{{ eduYear.name }}
          </span>
        </b-card-sub-title>
      </b-card>
    </b-row>
    <b-row v-if="students">
      <b-col cols="auto" v-for="student in students" :key="student.user.id">
        <b-link
          v-if="student.user.user_type === 'student'"
          :to="`/show-student/${student.user.type_id}`"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center mb-4 px-2"
          >
            <b-avatar
              size="6rem"
              :src="
                student.user.media && student.user.media.length
                  ? `${student.user.media[0].path}`
                  : profileImgaePlaceholder
              "
              :img-alt="student.user.first_name"
            />
            <b-card-sub-title class="mt-2">
              {{ `${student.user.first_name} ${student.user.last_name}` }}
            </b-card-sub-title>
          </div>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import loading from "@/components/loading/loading.vue";
import {
  BCard,
  BRow,
  BCol,
  BCardSubTitle,
  BAvatar,
  BLink,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BAvatar,
    loading,
    BCardSubTitle,
  },
  data() {
    return {
      isLoading: true,
      course_id: null,
      students: null,
      courseDetails: null,
      profileImgaePlaceholder: require("@/assets/images/avatars/2-small.png"),
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.course_id = this.$route.params.id;
      this.getStudents(this.$route.params.id);
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getStudents(id) {
      try {
        const res = await this.$http.get(`/guardian/courses/${id}/students`);
        this.students = res.data.data;
        this.courseDetails = res.data.course;
        console.log(res.data.data);
      } catch (error) {
        for (const key in error.response.data.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_courseStudents.scss";
</style>
